import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import { isNullOrWhiteSpace } from '@/utils';
import {
  Tag,
  Map,
  SearchHeader,
  Sidebar,
  Activity,
  Loader,
  MobileFilter,
} from '@/components';

export default {
  metaInfo: {
    title: 'Event filterpage',
  },
  name: 'eventsframe',
  components: {
    [Tag.name]: Tag,
    [Map.name]: Map,
    [SearchHeader.name]: SearchHeader,
    [Sidebar.name]: Sidebar,
    [Activity.name]: Activity,
    [Loader.name]: Loader,
    [MobileFilter.name]: MobileFilter,
  },
  props: {
  },
  data: () => ({
    mapViewActive: false,
    mobileFilterActive: false,
    title: 'Activiteiten',
    searchPlaceholder: 'Zoek een activiteit...',
    possibleToUpdateUrl: false,
    map: {
      zoom: 8,
      points: [],
      center: [51.053662, 3.724824],
      showEvents: true,
    },
    created: false,
    page: 1,
    discipline: null,
    hidefilters: false,
    hidelocation: false,
    mapview: false,
  }),
  computed: {
    ...mapGetters('event', {
      events: 'getEventList',
      markers: 'getEventMarkers',
      isLoading: 'getLoadingState',
      buttonIsLoading: 'getButtonLoadingState',
      getEventFilter: 'getEventFilter',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
  },
  methods: {
    ...mapMutations('event', {
      clearEventList: 'CLEAR_EVENT_LIST',
      setEventFilterQuery: 'SET_EVENT_FILTER_QUERY',
      setEventFilterDate: 'SET_EVENT_FILTER_DATE',
      setEventFilterAge: 'SET_EVENT_FILTER_AGE',
      setEventFilterShowPrivate: 'SET_EVENT_FILTER_SHOW_PRIVATE',
      setEventFilterAvailablePlaces: 'SET_EVENT_FILTER_AVAILABLE_PLACES',
      setEventFilterPrice: 'SET_EVENT_FILTER_PRICE',
      setEventFilterTags: 'SET_EVENT_FILTER_TAGS',
      setEventFilterType: 'SET_EVENT_FILTER_TYPE',
      setEventFilterDiscipline: 'SET_EVENT_FILTER_DISCIPLINE',
      setEventFilterLocation: 'SET_EVENT_FILTER_LOCATION',
    }),
    ...mapActions('event', {
      getEvents: 'FETCH_DATA_EVENT_LIST',
      getEventMarkers: 'FETCH_DATA_EVENT_MARKERS',
    }),
    ...mapActions('discipline', {
      fetchDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    toggleMapView() {
      this.mapViewActive = !this.mapViewActive;
      if (this.mapViewActive) {
        this.queryEventMarkers();
      } else {
        this.clearEventList();
        this.page = 1;
        const filter = _.clone(this.getEventFilter);
        filter.page = 1;
        this.getEvents(filter);
      }
    },
    toggleMobileFilter() {
      this.mobileFilterActive = !this.mobileFilterActive;
      if (this.mobileFilterActive) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'unset';
      }
    },
    search(searchQuery) {
      this.setEventFilterQuery(searchQuery);
    },
    updateRouteQuery() {
      const filter = this.getEventFilter;
      const queryObject = {};
      if (!isNullOrWhiteSpace(filter.query)) {
        queryObject.q = filter.query;
      }

      if (typeof filter.age !== 'undefined'
        && filter.age !== null
        && filter.age.length !== null
        && filter.age.length > 0) {
        queryObject.leeftijd = `${filter.age[0]}-${filter.age[1]}`;
      }

      if (typeof filter.showPrivate !== 'undefined' && filter.showPrivate !== null) {
        queryObject.showprivate = filter.showPrivate;
      }

      if (typeof filter.availablePlaces !== 'undefined'
        && filter.availablePlaces !== null
        && filter.availablePlaces.length !== null
        && filter.availablePlaces.length > 0) {
        queryObject.beschikbareplaatsen = `${filter.availablePlaces[0]}-${filter.availablePlaces[1]}`;
      }

      if (typeof filter.price !== 'undefined'
        && filter.price !== null
        && filter.price.length !== null
        && filter.price.length > 0) {
        queryObject.prijs = `${filter.price[0]}-${filter.price[1]}`;
      }

      if (typeof filter.date !== 'undefined'
        && filter.date !== null
        && filter.date.length !== null
        && filter.date.length > 0) {
        queryObject.datum = `${filter.date[0]}_${filter.date[1]}`;
      }

      // eslint-disable-next-line eqeqeq
      if (this.hidefilters) {
        queryObject.hidefilters = true;
      }

      // eslint-disable-next-line eqeqeq
      if (this.hidelocation) {
        queryObject.hidelocation = true;
      }

      // eslint-disable-next-line eqeqeq
      if (this.mapview) {
        queryObject.mapview = true;
      }

      if (typeof filter.tags !== 'undefined'
        && filter.tags !== null
        && filter.tags.length !== null
        && filter.tags.length > 0) {
        queryObject.tags = filter.tags.join(',');
      }

      if (!isNullOrWhiteSpace(filter.type)) {
        queryObject.type = filter.type;
      }

      if (filter.discipline !== 0 && filter.discipline !== null) {
        this.discipline = this.disciplineList.find((d) => d.id === filter.discipline);
        queryObject.discipline = this.discipline.name;
      }

      if (!isNullOrWhiteSpace(filter.location)) {
        queryObject.locatie = filter.location;
      }

      this.$router.replace({ query: queryObject }).catch(() => { });
    },
    updateEventFilterFromQuery() {
      const queryObject = this.$route.query;
      if (_.isEmpty(queryObject)) {
        this.possibleToUpdateUrl = true;
        this.updatingFilter = false;
        return;
      }

      if (!isNullOrWhiteSpace(queryObject.q)) {
        this.setEventFilterQuery(queryObject.q);
      }

      // eslint-disable-next-line eqeqeq
      if (!isNullOrWhiteSpace(queryObject.hidefilters) && queryObject.hidefilters == 'true') {
        this.hidefilters = queryObject.hidefilters;
      }

      // eslint-disable-next-line eqeqeq
      if (!isNullOrWhiteSpace(queryObject.hidelocation) && queryObject.hidelocation == 'true') {
        this.hidelocation = queryObject.hidelocation;
      }

      // eslint-disable-next-line eqeqeq
      if (!isNullOrWhiteSpace(queryObject.mapview) && queryObject.mapview == 'true') {
        this.mapview = queryObject.mapview;
        this.toggleMapView();
      }

      if (!isNullOrWhiteSpace(queryObject.leeftijd)) {
        this.setEventFilterAge(queryObject.leeftijd.split('-'));
      }

      if (!isNullOrWhiteSpace(queryObject.showprivate)) {
        this.setEventFilterShowPrivate(queryObject.showprivate);
      }

      if (!isNullOrWhiteSpace(queryObject.beschikbareplaatsen)) {
        this.setEventFilterAvailablePlaces(queryObject.beschikbareplaatsen.split('-'));
      }

      if (!isNullOrWhiteSpace(queryObject.prijs)) {
        this.setEventFilterPrice(queryObject.prijs.split('-'));
      }

      if (!isNullOrWhiteSpace(queryObject.datum)) {
        this.setEventFilterDate(queryObject.datum.split('_'));
      }

      if (!isNullOrWhiteSpace(queryObject.tags)) {
        this.setEventFilterTags(queryObject.tags.split(','));
      }

      if (!isNullOrWhiteSpace(queryObject.type)) {
        this.setEventFilterType(queryObject.type);
      }

      if (!isNullOrWhiteSpace(queryObject.discipline)) {
        this.discipline = this.disciplineList.find((d) => d.name.includes(queryObject.discipline));
        this.setEventFilterDiscipline(this.discipline.id);
      }

      if (!isNullOrWhiteSpace(queryObject.locatie)) {
        this.setEventFilterLocation(queryObject.locatie);
      }
    },
    loadMoreEvents() {
      this.page += 1;

      const filter = _.clone(this.getEventFilter);
      filter.page = this.page;
      filter.loadButton = true;
      this.getEvents(filter);
    },
    queryEventMarkers() {
      const filter = _.clone(this.getEventFilter);
      filter.pageSize = 100000;
      filter.page = 1;
      this.getEventMarkers(filter);
    },
    async getEventFilterWatchFunction(updatedEventFilter) {
      this.clearEventList();
      this.page = 1;
      const filter = updatedEventFilter;
      filter.page = this.page;

      if (this.mapViewActive) {
        this.queryEventMarkers();
      } else {
        this.getEvents(filter);
      }

      this.updateRouteQuery();
    },
  },
  created() {
    this.created = true;
    this.fetchDisciplineList()
      .then(async () => {
        this.updateEventFilterFromQuery();
        await this.getEventFilterWatchFunction(this.getEventFilter);
        this.created = false;
      });
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
    getEventFilter: {
      handler(updatedEventFilter) {
        // when the filter changes reset this.page and clear the events
        if (!this.created) { this.getEventFilterWatchFunction(updatedEventFilter); }
      },
      deep: true,
    },
  },
};
