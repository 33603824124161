import { render, staticRenderFns } from "./EventSearchFrame.vue?vue&type=template&id=1018eb70&scoped=true&"
import script from "./EventSearchFrame.controller.js?vue&type=script&lang=js&"
export * from "./EventSearchFrame.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./EventSearchFrame.styles.scss?vue&type=style&index=0&id=1018eb70&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1018eb70",
  "5bac260f"
  
)

export default component.exports